@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

a{
    -webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.login_form{
    background: url('https://i.pinimg.com/originals/ef/a2/4a/efa24a6384b0c5bcf7b9a5c08f3c28fe.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    color: black;
}

.err_msg{
    /* color: rgb(255, 174, 0); */
    color: red;
    font-size: small;
}

.Login_container{
    border-radius: 2rem;
    background-color: white;
    width: 400px;
    height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 4.6rem;
    overflow: auto;

}

.Login_container::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.Login_container::-webkit-scrollbar {
    background-color: transparent;
}


.email_input{
    color: #0D395C;
    outline: none;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid rgba(209, 207, 207, 0.788);
    padding: 0.4rem 0;
}

.pass_input{
    border: none;
    color: #0D395C;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid rgba(209, 207, 207, 0.788);
    padding: 0.4rem 0;

}

.email_section{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

}
.pass_section{
    margin: 2rem 0;
    display: flex;
    flex-direction: column;

}

.input_login{
    background-color: #90ADCD;
    color: white;
    border: none;
    border-radius: .4rem;
    padding: .4rem;
    font-size: x-large;
    cursor: pointer;
}

.changelogintype:hover{
    cursor: pointer;
}

.login_button{
    display: flex;
    justify-content: center;
}

.avatar{
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-top: 1rem;
    padding: 5px;
    background-color: #90ADCD;
}

.Login_container a {
    display: grid;
    place-content: center;
    margin-top: 0;
    padding: 0;
}

@media screen and (max-width:440px) {
 .Login_container{
     background-color: white;
     border-radius: 0px;
     height: 75vh;
     width: 56%;
 }   

 ::placeholder {
     color: white;
 }
 .login_form{
     min-height: 100%;
     overflow:hidden;
     background: url('./web1.jpg');
 }
 .email_input{
     font-size: 20px;
 }

 .pass_input{
    font-size: 20px;
}

.err_msg{
    font-size: 17px;
}

 .input_login{
     font-size: x-large;
     background-color: black;
 }
}

@media screen and (max-width:380px) {
    .email_input{
        font-size: 25px;
    }
   
    .pass_input{
        font-size: 25px;
    }
}

@media screen and (max-width:360px) {
    .email_input{
        font-size: large;
    }
   
    .pass_input{
        font-size: large;
    }
}

