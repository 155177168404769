@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@1000&family=Roboto:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap');


.main-cozy-page{
    margin: 0;
    font-family: "Roboto";
    box-sizing: border-box;
    background-image: linear-gradient(to bottom right, #FABA73, #F7B36D);
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
}

.main-cozy-container{
    border-radius: 1rem;
    height: 90vh;
    width: 70%;
    background-image: linear-gradient(to bottom left, #FBE2C6, #E7CDB6);
    display: flex;
    flex-direction: row;
    width: 80%;
    /* justify-content: space-around; */
    margin: 0 6rem;
}

.cozy-man-photo{
    margin-left:1rem;
    position:relative;
    left:3rem;
    width: 650px;
    display: flex;
    justify-items:baseline;
    display: flex;
    align-self: flex-end;
}

.cozy-man-photo-img{
    width: 95%;
    object-fit: contain;
    height: 100%;
}

.chat-bot-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;
    margin-right: 2rem;

}

.top-div-cozy{
    margin-top: 0 !important;
}

.cozy-man-title-text{
    width: 70%;
    margin: 0.5rem 0 0 0;
    font-size: 25px;
    font-family: 'Noto Sans KR', sans-serif;
}

.cozy-whole-text-area{
    overflow-y: auto;
    width: 100%;
    max-height: 350px;
    display: flex;
    position: relative;
    padding-bottom: 2rem;
    right: 4rem;
    flex-direction: column;
    align-items: center;
}

.ai-avatar{
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.cozy-first-message-area{
    border-radius: 1rem;
    
    background-color: #FDEBDB;
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 84%;
    margin: 2rem 0;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.cozy-first-message{
    padding-left:1rem ;
    margin-left: auto;
    font-size: medium;
    height: fit-content;
}

.cozy-text-input{
    background-color: #FABA73;
    border: none;
    z-index: 1;
    border-radius: 2rem;
    width: 70%;
    background-color: #2B3034;
    color: white;
    margin-top: 1rem;
    padding: 15px 20px;
    font-size: 20px;
}

::-webkit-scrollbar {display:none;}


input {
    height: 10px;
} 

::-webkit-input-placeholder {
    color: white
    }

.switch-langauge{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
}

.cozy-btton{
    margin-top: 0rem;
    background-color: #2B3034;
    color: white;
    border: none;
    font-size: medium;
    border-radius: 2rem;
    padding: 1rem;
    margin: 1rem;
}

.cozy-plant{
    position: absolute;
    left: 75rem;
    z-index: 2;
    top: 33.9rem;
    width: 200.9px;
    height: auto;
}