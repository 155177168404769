@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap');

.payment-main{
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    font-family: 'Quicksand', sans-serif;
    background-color: #21D4FD;
    background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);    
}

.card-heading{
    font-weight: 500;
}

.card-summary{
    font-weight: 500 !important;
    font-size: 23px;
}

.card-cost{
    display: flex;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    font-size: large;
    padding-top: 2rem;
    height: 40px;
    position: relative;
    border-bottom: 1px dashed grey;
}

.pay-card{
    display: flex;
    flex-direction: row;
    background-color: white;
    max-width: 900px;

}

img{
    height: 300px;
    width: 300px;
    object-fit: cover;
}
.card-leftside{
    margin: auto;
    display: flex;
    align-items: center;
}
#back-button-payments{
    background-color: black !important;
    border-radius: 20px !important;
}

.card-rightside{
    overflow-y: auto;
    margin: auto;
    max-width: 400px;
    width: 100%;
    height: 100%;
}

.pay-card{
    display: flex;
    justify-content: center;
    width: 80%;
    height: 70vh;
    padding: 1rem;
    border-radius: 2rem;    
}

.card-field{
    font-size: medium;
    margin: 1.3rem 0;
}

.card-input{
    width: 96%;
    border: none;
    border-bottom: 2px solid black;
    outline: none;
}

.card-button{
    margin-top: 1.3rem;
}

.card-button{
    background-image: linear-gradient(0deg, #08AEEA 0%, #2af5dd 100%);
    color: white;
    width: max-content;
    height: 35px;
    border-radius: 2rem;
    margin-top: 2rem;
    font-size: medium;
    outline: none;
    border: none;
}

.payment-steps{
    font-size: smaller;
}

.card-button:active {
    background-image: linear-gradient(0deg, #046588 0%, #15887b 100%);

  }

.card-button-container{
    width: 100%;
    display: grid;
    place-items: center;
}

.card-button-container-second-page{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 630px) {
    .card-vector{
        display: none;
    }
}