.search_input_tiqo{
    border: 10px solid red;
}

.tiqo-lnk-main{
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;

}

.tiqo_search{
    font-family: "Roboto";
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tiqo-yt-type{
    color: white;
    font-size: 30px;
    margin-bottom: 0;
}

.tiqo-yt-type-nt{
    margin-top: 0;
    font-size: 15px;
    color: white;

}

#yt-link-input{
    color: black;
    border: none;
    height: 40px;
    width: 300px;
    padding-left: 1rem;
    border-radius: 20px;
    padding-right:3rem
}

.input-yt-dv{
    display: flex;
    align-items: center;
    color: black;
}

.send-yt-video{
    background-color: #A6DF42;
    padding: 4px;
    border-radius: 50%;
    position: relative;
    right: 10%;
    cursor: pointer;
}

/* MODAL CSS */

.tiqo-modal, .tiqo-modal-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.tiqo-modal-overlay  {
    background: rgba(49,49,49,0.8);
}
.tiqo-modal-content {
    font-family: "Roboto";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.close-tiqo-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}


.option-llm-tiqo{

    margin: 0.54rem;
    border-radius:10px;
    background-color: white;
    padding: 10px;
}