.main-body-tiqo-edit{
    width: 100vw;
    min-height: 100vh;
    background-color: #E2E0E2;
}

.tiqo_edit_collector_whole{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Roboto";
}

.tiqo_edit_title{
    padding: 1rem;
    font-size: larger;
}

.tiqo_edit_cards_box{
    width: 90%;
    align-items: center;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.edit_card_tiqo{
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.backhomeqdit{
    font-size: medium;
    text-decoration: underline;
    
}

.tiqo-edit-question-title,.tiqo-edit-answer{
    font-size: 23px;
    padding-bottom: 0.3rem;
}

.tiqo-edit-question{
    text-align: center;
    padding: 0 0.5rem;
}

.tiqo-edit-answer{
    margin: 0.5rem 0;
}

.card_edit_ques,.card_edit_ans{
    /* padding: 0.5rem; */
    width: 300px;
    height: max-content;
    border-radius: 10px;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;

}

.card_edit_ques{
    background-color: #0E1D38;
    color: white;
    padding-top: 1rem;
    padding-bottom: 3rem;
    justify-content:flex-start;
}

.card_edit_ans{
    color: black;
    background-color: #FFF5E4;
    padding: 1rem 0;
    position: relative;
    bottom: 1rem;
}

.card_edit_ans textarea, .card_edit_ques textarea{
    width: 80%;
    border: none;
    border-radius: 20px;
    resize: none;
    height:100px;
    padding: 10px;
    font-family: "Roboto";
}

.save-edit-tiqo{
    margin: 2rem;
    border: none;
    margin-left: auto;
    padding: 20px;
    color: black;
    background-color: #FFF5E4;
    font-size: medium;
    border-radius: 5px;
}

.confirm-edit{
    margin: 2rem;
    border: none;
    color: Black;
    padding: 5px;
    background-color: #A8D1E7;
    font-size: medium;
    border-radius: 5px;
}

.textarea_question{
    height: 50px !important;
}

textarea:is(:focus,:valid){
    border-width: 2px;
    padding: 14px;
    border-color: #4671EA;
}