@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap');

body {
	background: #8C52FF;
}

.tiqoflash-whole{
	font-family: 'Noto Sans KR', sans-serif;
}

#tiqo-main-label-flashcards {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 380px;
	height: 400px;
	perspective: 1000px;
	transform-style: preserve-3d;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.neej{
	margin-top: 4rem;
	background-color: #A6DF42;
	border: none;
	font-size: 25px;
	border-radius: 10px;
	height: 60px;
	width: 90px;
	margin: 0 250px;
}

.buttons-tiqo-nav{
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.flip-card {
	position: relative;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: all 0.5s ease-in-out;
	z-index: 1;
}

.flip-card .front,
.flip-card .back {
	position: absolute;
	width: 100%;
	height: 100%;
	text-align: center;
	background: #A6DF42;
	backface-visibility: hidden;
	border-radius: 20px;
}

.flip-card .back {
	transform: rotateX(180deg);
	color: #000;
	background: #A6DF42;
}

#tiqo-main-label-flashcards:hover .flip-card {
	transform: rotateX(2deg);
	box-shadow: 0 20px 20px rgba(50, 60, 60, 0.2);
}

#my-cool-input {
	display: none;
}

:checked + .flip-card {
	transform: rotateX(180deg);
}
#tiqo-main-label-flashcards:hover :checked + .flip-card {
	transform: rotateX(179deg);
	box-shadow: 0 20px 20px rgba(255, 255, 255, 0.1);
}

.front {
	width: 110px;
	height: 110px;
	margin: 30px 0 20px 0;
	border-radius: 50%;
}

.answer-tiqo{
	height: 48%;
	overflow-y: scroll;
}

.q_anser{
	height: 70%;
	padding: 0 1rem;
}

.front,.q_anser{
	animation: transitionIn 0.75s;
}

@keyframes transitionIn {
	from{
		opacity: 0;
		transform: rotateX(-10deg);
	}

	to{
		opacity: 1;
		transform: rotateX(0);
	}
}

.nav-tiqo{
	margin-top: 70vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.back-to-main-tiqo{
	margin-top: 10vh;
	background-color:#A6DF42;
	padding: 10px;
	border-radius: 10px;
}

.tiqo-congrats-quiz{
	text-align: center;
	font-size: larger;
	font-weight: 500;
	color: lightskyblue;
}

.advice-tiqo{
	font-size: larger;
}


