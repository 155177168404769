#tiqo_quiz_card{
    flex-direction: column;
    background-color: #02244a !important;
    align-items: center;
    padding: 0 2rem;
}

#colour-tiqo-quiz{
    background-color: #02244a !important;
    
}

.get_response{
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    background-color: white;
    border-radius: 10px;
    padding:0.7rem 0.4rem;
}

.robot-tiqo-resp{
    font-size: large;

}

.tiqo-quiz-ansinp{

    background-color: teal;
    margin-bottom: 2rem;
    height: 40px;
    width: 400px;
    border: none;
    border-radius: 10px;
    padding: 0 1rem;
}

.back-tiqo{
    margin-top: 0.5rem;
    text-decoration: underline;
}

.tiqo-quiz-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem;
}

.next-tiqo-quiz{
    background-color: gold;
    border:  none;
    border-radius: 5px;
    width: 70px;
    height: 30px;
    margin-right: auto;
}

.tiqo-advice-box{
    margin-top: 0;
    font-family: "Roboto";
    color: white;
    font-size: 22px;
    height: 300px;
    /* border: 1px solid red; */
    overflow-y: scroll;
}
.tiqo-advice-disclaimer{

    color: rgba(255, 255, 255, 0.508);
    font-family: "Roboto";
    font-size: small;
}


.advice-tiqo{
    text-decoration: underline;
    margin: 0;
    padding: 0;
}

.tiqo-congrats-quiz{
	text-align: center;
	font-size: larger;
	font-weight: 500;
	color: #94fdfe;
}

.advice-tiqo{
	font-size: larger;
}

.tiqo_quiz_colour{
    background-color: #94fdfe
}

.tiqo-quiz-tovids{
    color: rgb(123 205 177) !important;

}
