.to-home-from-module{
    width: 200px;
    display: flex;
    padding: 0 1rem;
    align-items: center;
    border-radius: 20px;
    background-color: white;
}

.nothing-streatch{
    background-color: #9FC7AA;
}

.to-home-from-module-text{
    padding-left: 1rem;
}


.course-progress-cert{
    margin-top: 1rem;
    font-size: 30px;
}

.quizes-videos-lesson-whole{
    margin: auto;
    width: 70%;
}

.quizes-videos-lesson{
    display: flex;
    justify-content: space-between;
}

.line{
    margin: auto;
    width: 80%;
    border-bottom: 1px solid rgb(215, 211, 211);
}

.videos-catalogue-lesson{
    margin: auto;
    margin-top: 20px;
    height: 200px;
    width: 80%;
    overflow-y: scroll;
}

.lesson-name-padlock{
    font-size: medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin-bottom: 15px;
    margin: 0 10px;
    transition: all 0.3s;
}

.lesson-name-padlock:hover{
    color: #D52B77;
    margin-left:20px ;
}

.highlight-lesson-name{
    color: #D52B77;
    margin-left:20px ;
    font-weight: 600;
}


.colab-video-checkmark{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 90%;
}

.colab-btn{
    display: flex;
    align-items: center;
    border-radius: 20px;
    background-color: #B6CCD7;
    padding: 5px;
}

.colab-lnk{
    padding-right: 1rem;
}

.code-icon{
    font-size: 30px;
    padding: 0 1rem;
}


.marked-watch{
    display: flex;
    align-items: center;
    padding-right:1rem;
}

.watched-marker{
    display: grid;
    place-items: center;
    height: 40px;
    width: 40px;
    margin-left:1rem;
    border-radius:5px ;
    background-color: #b6ccd7be;
    cursor: pointer;
}


.key-lessons{
    margin-top: 2rem;
}


.key-lessons-title{
    font-size: 25px;
}

.key-lessons-desc{
    width: 80%;
    padding: 1rem;
    background-color: azure;
    border-radius: 20px;
    margin: auto;
}


.quiz-status-link{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 2rem;
}

.quiz-complete{
    display: flex;
    margin-left: 1rem;
    align-items: center;
}

.quiz-status-text{
    padding-left: 1rem;
    font-size: 15px;
}

.status-background{
    background-color: #B6CCD7;
    padding: 5px;
    font-size: medium;
}

.quiz-link-btn{
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #F47068;
    padding: 0 1rem;
}

.quiz-lnk{
    padding-right: 1rem;
}


.switch-lesson{
    margin: 0 2rem;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.prev-lesson{
    padding-left: 3rem;
}

.next-lesson{
    padding-left: 4rem;

}

.prev-lesson, .next-lesson{
    max-width: 250px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0.7rem 1rem;
    background-color: white;
}

.prev-lesson-name-text,.next-lesson-name-text {
    padding-left: 1rem;
    margin: 0;
}

.lesson-change-icon{
    font-size: larger;
}

@media screen and (max-width: 630px) {
    .next-lesson-name-text{
        width:150px;
    }
}

@media screen and (max-width: 540px) {
    .next-lesson-name-text{
        width:120px;
    }
}
@media screen and (max-width: 500px) {

    .module-header-title{
        font-size: 40px;
    }

    .quiz-link-btn{
        font-size: large;
    }

    .course-module-intro-img{
        margin: auto;
    }

    .course-module-intro-title, .course-module-intro-desc{
        padding-left: 1rem;
    }


    .prev-lesson, .next-lesson {
        padding: .5rem;
        max-width: 150px;
        display: flex;
        justify-content: space-between;
    }

    .switch-lesson{
        width: 90%;
        margin: 1rem 0;
    }

    .next-lesson-name-text{
        width:100px;
    }
}