
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');


/* HOME LANDING (SECTION 1) */
.App{
  font-family: 'Poppins', sans-serif;
}

.list-item{
  color: black;
  transition: all 0.2s;
}

.list-item:hover{
  transform: scale(1.05);
  cursor: pointer;
}

.home-landing {
  background-color: #90ADCD;
  height: max-content;
}

.msimbo-title{
  font-size: 2rem;
  padding: .5rem 0;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.ham_menu{
  display: none;
  color: white;
}

.msimbo-title-link{
  color: black;
}

.offerings{
  display: flex;
  margin: auto;
  width: 60%;
  justify-content: space-between;
}

.heading-main-image{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  height: 300px;
  padding: 7rem;  
}

.home-msimbo{
  border: 1px solid transparent;

}

.msimbo-heading-title{
  font-size: 60px;
  margin: 0;
}


.msimbo-short-desc{
  font-size: 40px;
}

.start-now{
  width: 60%;
  text-align: center;
  padding: 1px;
  background-color: #4984AE;
  border-radius: 4px;
  transition: all 0.2s;
}


.start-now:hover{
  background-color: #315875;
}


.main-image > img {
  width: 450px;
  height: 450px;
  object-fit: cover;
}



/* HOME MSIMBO (SECTION 2) */

.home-msimbo{
  background-color: #F6F9FC;
}

.what-is-msimbo{
  font-size: 50px;
  text-align: center;
}

.offerings-container{
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.msimbo-offerings{
  padding: 2rem 2rem;
  border-radius: 10px;
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  transition: all .2s ease-in-out; 
  margin-bottom: 6rem;
}

.msimbo-offerings:hover{
  transform: scale(1.1); 
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.offering-title{
  font-size: large;

}

.offering-desc{
  width: 300px;
  font-weight: 300;
  text-align: center;
}

#offering-img{
  font-size: 80px;
}

/* SECTION 3 */

.home-curriculum{
  background-color: #B9C7D2;
}

.curriculum-info-img{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.curric-info{
  margin: 0 2rem;
  width: 700px;
}

.curric-title, .project-title{
  font-size: 40px;
}

.curric-desc, .project-desc{
  font-size: 20px;
}



.explore-curric, .try-free-proj{
  background-color: #FF443A;
  height: 60px;
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9rem;
  margin-top: 4rem;
  margin-left: 2rem;
  border-radius: 5px;
}

.curriculum-img > img{
  width: 500px;
  height: 500px;
  object-fit: cover;
}


/* SECTION 4 (home-project)*/

.home-project{
  background-color: #FFF6EA;
}

.project-info-lst{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.project-lst, .project-info{
  max-width: 600px;
  margin: 0 2rem;
}

.project-lst, .colab-img{
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.lst-padding{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin:3rem 0;
  height: 50px;
  background-color:#128F8B;
  width: 350px;
  color: white;
}


/* SECTION 5 (HOME COLAB) */

.home-colab{
  background-color: #B9C7D2;
  padding: 3rem;
}

.colab-img> img{
  width: 500px;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
  margin: 1rem 2rem;
}

/* SECTION 6 (HOME CERT) */

.home-cert{
  background-color: #90ADCD;
}

.whole-cert{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
}

.cert-desc,.cert-title{
  text-align: center;
}

.cert-title{
  font-size: 60px;
}

.cert-desc{
  margin-top: 0;
  font-size: 20px;
}


.cert-start-course{
  background-color: #FF443A;
  height: 60px;
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9rem;
  margin-top: 4rem;
  margin-left: 2rem;
  border-radius: 5px;
}

.cert-img > img{
  width: 550px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}


.fade-slight{
  transition: all .1s ease-in-out; 
  cursor:pointer;
}

.fade-slight:hover{
  background-color: #ba322b;
}




.close-phone-menu{
  display: none;
}

@media screen and (max-width: 850px) {
  body{
      overflow-x: hidden;
  }

  .ham_menu{
      display: block;
      cursor: pointer;
  }

  .offerings{
    transform: translateX(100%);
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    background-color: #0D395C;
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    opacity: 0;
    transition: all 0.2s ease-in;
    position: fixed; 
  }


  .list-offering-item-top{
    padding-top: 4rem;
  }
  .list-offering-item-bot{
    padding-bottom: 4rem;

  }

  

  .list-item{
      color: white;
      font-size: 20px;
  }

  .offerings a{
      margin: 2rem 0;
  }

  .open{
      transform: translateX(0%);
      opacity: 1;
  }

  .close-phone-menu{
      display: block;
      color: rgba(255, 255, 255, 0.418);
      position: absolute;
      left: 1.5rem;
      top: 20px;
      font-size: 30px;
      cursor: pointer;
  }

  .project-info-lst{
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .project-lst{
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;
    width: 80%;
    margin-top: 2rem;
  }

  .lst-padding{
    margin: 0;
    padding: 1rem;
    width: 60%;
    margin-top: 1rem;
  }

  .lst-content{
    text-align: center;
  }

  .project-info{
    margin: auto;
    padding: 0 2rem;
  }

  .project-desc{
    width: 90%;
  }

  .explore-curric, .try-free-proj{
    margin:auto;

    margin-top: 3rem;
    margin-bottom: 3rem;
  }


  /* project-info-lst :: lst-padding */

  }

@media screen and (max-width: 780px) {
  .heading-main-image{
    flex-direction: column;
  }

  .main-image > img{
    width:300px;
    height:300px;
  }


  .heading-title{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .curriculum-img > img {
    width: 300px;
    height: 300px;
  }

}


@media screen and (max-width: 780px) {

  .cert-start-course{
    margin-left: 0;
  }
  .colab-img{
    margin: auto;
    display: grid;
    place-items: center;
  }

  .colab-img> img{
    width: 90%;
    height: 100%;
  }

  .cert-img > img{
    width: 300px;
    height: 100%;
  }

}



/* PHONES */

@media screen and (max-width: 500px) {

  .offerings{
    width: 100%;
}

  p{
    font-size: smaller;
  }

  .msimbo-title-link{
    font-size: 20px;
  }

  .msimbo-short-desc{
    font-size: 17px;
    width: 200px;
    text-align: center;

  }

  .what-is-msimbo{
    font-size: 40px;
    padding: 0 3rem;
  }

  .project-lst{
    display: flex;
    justify-content: center;
  }

  .msimbo-offerings{
    border: 1px solid #0d385c35;
  }

  .project-info{
    width: fit-content;
  }

  .project-desc,.curric-desc{
    font-size: medium;
  }

  .project-title{
    font-size: 30px;
  }
  
  .project-desc{
    width: 97%;
  }

  .heading-title{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media screen and (max-width: 500px){
  .try-free-proj {
    margin: auto;
    padding: 0.2rem 1.2rem;
    margin-top: 3rem;

  }

  .curric-desc{
    font-size: large;
  }

  .explore-curric{
    font-size: larger;
    margin-left: 0;
  }

  .ppp-adjust{
    width: 80%;
    font-size: larger;
  }

  .explore-curric{
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .project-desc{
    font-size: large;
  }


}