@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

.quiz_page{
    border: 4rem solid red;
    margin: 0;
    padding: 0;
}
.quiz_page_colour{
    height: 100vh;
    width: 100vw;
   background-color: aqua;
   display: flex;
   align-items: center;
   justify-content: center;

}

.current_quiz_number,.current_quiz_quest,.quiz_answer,.current_quiz_loading_data{
    color: white;
    font-size: larger;
    font-family: "poppins";
}
.quiz_desc{
    padding-left: 20px;
    flex:0.7

}

.current_quiz_number{
    margin-top: 30px;
    
}

.quiz_card{
    background-color: black;
    display: flex;
    border-radius: 2rem;
    justify-content: space-around;
    min-height: 300px;
    box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
    width: 80%;
    min-width: 500px;
    flex-grow: 0.2;
    max-width: 800px;
}

/* .quiz_desc, .quiz_content{
    width: 50%;
} */

.quiz_content{
    padding:0 1rem ;
    padding-top: 2rem;
    flex: 0.3;
}

.answer_container{
    display: flex;
    justify-content: center;
    
}

.quiz_answer{
    border: 3px solid teal;
    padding: 3px;
    border-radius: 9px;
    font-size: 17px;
    text-align: center;
    margin: 10px 0;
    color: white;
}

.quiz_answer:hover{
    cursor: pointer;
    background-color: white;
    color: black;
}


.quiz_image{
    height:200px;
    width: 450px;
    overflow-y:auto;
}

.quiz-image-div > img{
    /* border: 2px solid red; */
    height: 60%;
    width: 100%;
    object-fit: cover;
}


.quiz-to-vids{
    color: rgb(255, 127, 212) !important;
}



pre {
    white-space: pre-wrap;
    background: rgb(0, 0, 0);
}
