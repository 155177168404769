.course{
  font-family: 'Poppins', sans-serif;
}

.course-page-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.course-heading-title{
    padding: 0 2rem;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 70px;
}

.course-short-desc{
    font-size: x-large;
    padding: 2rem 13rem;
}

.buy-course{
    margin-top: 2rem;
    background-color: #4984AE;
    border-radius: 10px;
    font-size: 30px;
    width: 300px;
    color: white;
    transition: all 0.2s;
}

.buy-course:hover{
    background-color: #345e7c;
    cursor: pointer;

}


/* SECTION 2 */

.course-landing{
    padding: 6rem 0;
    height: 100%;
    background-color: #9FC7AA;
}

#troublesome-vid{
    height: 400px;
}

#course-book-highlight{
    color: #128F8B;
    font-weight: 600;
}

.course-progress-introduction{
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100%;
    margin: 0 3rem;

}
/* LEFT SIDE */


.course-landing-progress{
    align-self: flex-start;
    position: sticky;
    height: auto;
    top: 5rem;
    min-width: 300px;
    height: 650px;
    border-radius: 10px;
    background-color: white;

}

 .scrollable{
    display: flex;
    flex-direction: column;
    text-align: center;
} 

.progress-certificate{
    height: 100px;
    width: 80%;
    margin: auto;
    background-color: #128F8B;
    position: relative;
    top: -3rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.certificate-icon-course{
    
    font-size: 90px;
    color: #C7EBF8;
}


.course-progress-cert{
    font-size: 25px;
}

.cource-circular-progress{
    transition: all 0.2s ease-in-out !important;   
 } 

.cource-circular-progress::before{
    content: " ";
    position: absolute;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: white;
}

.percentage-done{
    font-size: 25px;
    z-index: 2;
}


.course-progress-quizzes,.course-progress-videos{
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 2rem;
}

.progress-quizzes-tally,.progress-videos-tally{
    font-size: 17px;
    margin: 0;
}

.course-get-progress-cert{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #128F8Bed;
    border-radius: 10px;
    width: 200px;
    height: 70px;
    margin-top: 2rem;
    transition: all 0.2s;
}


.course-get-progress-cert:hover{
    background-color: #0b5452ed;
    cursor: pointer;

}

.get-cert{
    font-size: 20px;
}

/* RIGHT SIDE */

.course-modules{
    height: max-content;
    margin: 0 3rem;
}

.course-module-intro{
    margin-bottom: 2rem;
    padding-left: 2rem;
    padding-bottom: 3rem;
    margin: .5rem 0;
    width: 95%;
    max-width: 900px;
    min-width: 500px;
    height: 100%;
    border-radius: 10px;
    background-color: white;
}

section{
    padding: 2rem 0;
}

.course-module-intro-title{
    padding-top: 2rem;
    font-size: 30px;
}

.course-module-intro-desc{
    width: 90%;
    font-size: 20px;
}

.course-module-intro-img{
    max-height: 400px;
    width: 90%;
    padding-bottom: 1.5rem;
}

.course-module-intro-img > img{
    border-radius: 20px;
    height: 400px;
    width: 100%;
    object-fit: cover;
}



/* AFTER INTRO */

.course-module-oop{
    margin-bottom: 2rem;
    padding-left: 2rem;
    padding-bottom: 3rem;
    margin: 1rem 0;
    width: 95%;
    max-width: 900px;
    min-width: 500px;
    height: 100%;
    border-radius: 10px;
    background-color: white;
}

.course-module-oop-title{
    padding-top: 2rem;
    font-size: 30px;
}

.module-description{
    width: 90%;
    font-size: 20px;
}

.module-info{
    display: flex;
    justify-content: space-between;
}

.module-in-line{
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.module-react-icon{
    font-size: 27px;
    margin-top: 5px;
    border-radius: 50%;
    background-color: #128F8B;
    color: #C7EBF8;
}


.module-text{
    margin-left: 1rem;
}


/* CLASS PROGRESSION BAR */

.module-start-lessons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    height: 80px;
    padding: 10px 0;
    width: 95%;
    margin: 3rem 0;
    background-color: #128F8B;
}

.lesson-progression{
    height: 30px;
}

.left-start-icon{
    font-size: 50px;
    padding-right:2rem;
    padding-left:1rem;

}

.left-start{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lesson-progression{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lesson-progress-bar{
    width: 130px;
    height: 15px;
    background-color: white;
    border-radius: 5px;
}

.lesson-progress-percent{
    padding-left: 3rem;
}

.right-start{
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-start-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    background-color: #6cb0ae;
    margin: 0 2rem;
    height: 40px;
    border-radius: 5px;
    color: white;
    font-size: larger;
}

.right-start-btn:hover{
    background-color: #428280;
    cursor: pointer;
}


/* DONE CLASS PROGRESSION BAR */

.open{
    z-index: 3;
}


@media screen and (max-width: 1100px) {

    .curriculum-info-img{
        flex-wrap: wrap;
    }

    .progress-certificate{
        z-index: 1;
    }

    .msimbo-heading-title{
        font-size: 40px;
    }

    .msimbo-short-desc{
        font-size: larger;
    }


    .course-progress-introduction{
      display: flex;
      flex-direction: column;
    } 


    .course-landing-progress{
        width: 90%;
        max-width: 800%;
        align-self: center;
        position: static;
    }
    .course-progress-introduction{
        margin: 0;
        margin-top: 2rem;
    }

    .course-heading-title{
        font-size:50px;
    }

    .course-module-intro, .course-module-oop{
        min-width: 100px;
    }

    .course-modules{
        margin-top:2rem ;
    }
  }

@media screen and (max-width: 660px) {


    .msimbo-heading-title,.course-heading-title{
        font-size: 30px;
      }

    .module-start-lessons{
        flex-direction: column;
    }

    .module-start-lessons{
        height: fit-content;
    }

    .right-start-btn{
        margin-top: 1rem;
    }

    .colab-video-checkmark{
        flex-direction: column;
        align-items: center;
    }

    .marked-watch{
        margin-top: 3rem;
        font-size: larger;
    }


    .colab-img> img{
        margin-top: 3rem;
    }

    .cert-start-course{
        width: 90%;
    }

    .cert-title{
        font-size: 40px;
    }
}

@media screen and (max-width: 500px) {

    .course-short-desc{
        padding: 1rem 2rem;
    }

    .course-module-intro-img{
        padding-bottom: 0;
    }

    .prev-lesson, .next-lesson {
        padding: 0;

    }

    .module-in-line{
        justify-content: flex-start;
    }



    .course-modules{
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        }

        
    .course-module-intro-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
    }
    
    .course-module-oop{
        width: 80%;
    }

    .course-module-intro{
        width: 90%;
    }

    .course-module-intro{
        padding-left: 0;
    }

    .key-lessons-title{
        text-align: center;
    }

    .course-module-intro-title,.course-module-oop-title{
        font-size: x-large;
    }

    .course-module-intro-desc,.module-description{
        font-size: medium;
    }

    .course-module-intro-img > img {
        height: 200px;
    
    }

    .module-info{
        flex-direction: column;
    }

    .module-start-lessons{
        height: 150px;
    }

    .lesson-progression{
        flex-wrap: wrap;
    }

    .offerings a{
        margin: 2rem 0;
    }

    .switch-lesson{
        font-size: smaller;
        justify-content: space-between;
    }
    
}