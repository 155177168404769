@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
.tiqo-homeback{
    width: 100vw;
    height: 100vh;
    background-color: #A8D1E7;
    display: grid;

    place-items: center;
}

.tiqo-home-center-div{
    border-radius: 20px;
    width: 80%;
    height: 80%;
    background-color: #A8D1E7;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;

    justify-content:space-between ;
}

.tiqo_options{
    background-color: white;
    border-radius: 20px ;
    max-height: 100%;
    width: 30%;
}

.tiqo_options_text{
    font-family: 'Roboto' !important;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tiqo_collection{
    border-radius: 20px ;
    background-color: #E7F6FC;
    width: 60%;
    padding: 0 3rem;

    
}

.collection_box{
    padding-top: 1rem;
    margin: 0.5rem 0;
    /* border: 1px solid red; */
    background-color:white;
    border-radius: 20px;
    height: 75%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
}

.tiqo_card{
    font-family: "Roboto";
    background-color: #A8D1E7;
    border-radius: 20px ;
    margin: 0 1rem;
    margin-bottom: 3rem;
    width: 290px;
    height: 330px;
    
}

.broke-ahh{
    background-color: #a19f9f !important;
    transform: none !important;
}

.tiqo_card_inf{
    padding:0 0.7rem;
}

.tiqo_card img{
    border-radius: 20px;
    height: max-content;
    width: 290px;

}

.add_quiz{
    font-family: 'Roboto' !important;
    display: flex;
    align-items: baseline;
    justify-content: center;

}

.tiqo-add-quiz-entire-div{
    margin-top: 7%;
    padding: 0.6rem;
    margin-bottom: 0;
    height: 8%;
    margin-right: 20px;
    margin-left: auto;
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    background-color: #C1FF72;

}

.add_log{
    font-size: 26px;
}


.no-videos-tiqo{
    margin-left: 1rem;
    font-size: large;
    font-family: "Roboto";
}


.tiqo-quiz-cdown{
    padding: 1rem;
    border-radius: 10px;
    background-color: #FF9045;
}

.tiqo_options_acc_text{
    margin: 0;
    padding: 1rem;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Roboto', cursive;

}

.add_tiqo{
    padding-left: 1rem;
}

.adjust-tiqo-add{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.coloured_tiqo_opt{
    cursor: pointer;

    background-color: #BEC4ED;
}

.tiqo_options_acc_text_small{
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    font-family: 'Roboto' !important;

}

.logout_tiqo{
    background-color: #F64D48;
    padding: 0.5rem 0.9rem;
    border-radius: 10px;
}

.quiz-flas-tiqo{
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 25px;
    justify-content: space-around;
    
}

.quiz-flas-tiqo-btn{
    background-color: white;
    border-radius: 20px;
    padding: 8px 20px;
}

.quiz-flas-tiqo-btn:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
    transform: scale(1.03);
    transition: transform 0.3s ease-in-out;
  }

